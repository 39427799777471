@keyframes blink {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.notification-wrapper {
  position: relative;
}

.notification-badge {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  animation: blink 2.5s infinite;
  width: 35px;
  height: 20px;
  border-radius: 10px;
  top: -9px;
  font-size: 14px;
  padding: 4px 7px;
  color: white;
  background-color: #ff9800;
}

#message-container {
  right: 28px;
}

#notification-container {
  right: 14px;
}